import React, { useState, useRef } from "react";
import logo from './images/excel.png'
import cancel from './images/cancel.png'
import checked from './images/checked.png'
import failedIcon from './images/failed.png'

import axios from 'axios'

import './App.scss';

export default () => {

  const inputFile = useRef(null);
  const [file, setFile] = useState()
  const [dragAndDrop, setDragAndDrop] = useState()
  const [loginUser, setLoginUser] = useState({})
  const [uploaded, setUploaded] = useState(false)
  const [failed, setFailed] = useState(false)
  const [sendEmail, setSendEmail] = useState(false)

  const dragOver = (e) => {
    e.preventDefault();
  }

  const dragEnter = (e) => {
    e.preventDefault();
    setDragAndDrop(true)
  }

  const dragLeave = (e) => {
    e.preventDefault();
    setDragAndDrop(false)
  }

  const fileDrop = (e) => {
    e.preventDefault();
    setDragAndDrop(false)
    const files = e.dataTransfer.files;
    if (files.length) {
      setFile(files[0])
    }
  }

  const upload = async () => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(`https://dev.crmcloudapi.com/v3/api/contact/codes/import?sendEmail=${sendEmail}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })

      setUploaded(true)
      setTimeout(() => {
        setFile(null)
        setUploaded(false);
        setSendEmail(false)
      }, 1000);

    } catch (e) {
      setFailed(true)
      setUploaded(true)

      setTimeout(() => {
        setFile(null)
        setUploaded(false);
        setFailed(false)
        setSendEmail(false)
      }, 1000);
    }
  }

  return (
    <div className="app">
      <div className='uploader'>
        {uploaded ?
          <>
            {failed ?
              <>
                <img src={failedIcon} className='checked' />
                <label className='success-text'>Upload <span>failed</span>!</label>
              </>
              :
              <>
                <img src={checked} className='checked' />
                <label className='success-text'>File successfully <span>uploaded</span>!</label>
              </>
            }
          </>
          :
          <>
            <input
              className='upload-input'
              type="file"
              ref={inputFile}
              onChange={(e) => setFile(e.target.files[0])}
            />
            <div className='drag-and-drop'
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              style={{ background: dragAndDrop ? ' rgb(120, 112, 242)' : 'white' }}
            >
              {dragAndDrop ?
                <>
                  <img src={logo} />
                  <label className='title' style={{ color: 'white', fontSize: '30px', fontWeight: '700' }}>Drop'em right here!</label>
                </>
                :
                <>
                  <img src={logo} />
                  <div className='texts'>
                    <label className='title'>Drag & Drop your excel file</label>
                    <label className='subtitle'>or <span onClick={() => inputFile.current.click()}>browse files</span> on your computer</label>
                  </div>
                </>
              }
            </div>
            {file &&
              <div className='files'>
                <div className='file'>
                  <div className='file-left'>
                    <img src={logo} />
                    <div className='info'>
                      <label className='name'>{file.name}</label>
                      {/* <div className='file-infos'>
                        <label className='file-info'>{file.type.split('/')[0]}</label>
                        <label className='file-info'>{file.size}</label>
                        <label className='file-info'>{file.type.split('/')[1]}</label>
                      </div> */}
                    </div>
                  </div>
                  <img className='cancel' src={cancel} onClick={() => setFile(null)} />
                </div>
              </div>
            }
            <div className='send-email'>
              <label>Send email</label>
              <div className={`switch-btn ${sendEmail && 'active-switch'}`} onClick={() => setSendEmail(!sendEmail)}>
                <div className='switch' />
              </div>
            </div>
            <button onClick={upload}>Upload</button>
          </>
        }
      </div>
    </div>
  );
}